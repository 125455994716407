import axios from 'axios'
import {delToken, getToken} from "./auto.js";
import { MessagePlugin } from 'tdesign-vue-next';
import router from "../router";

const service = axios.create({
    baseURL:'https://tzwsxd.zgwdwl.com/admin',
    timeout:5000,
    withCredentials:true
})

service.interceptors.request.use(
    async config => {
        if(await getToken()){
            config.headers['token'] = await getToken().token
        }
        return config
    },
    error => {
        MessagePlugin.error({content:JSON.stringify(error)})
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        let res = response.data
        if(res.code===102){
            MessagePlugin.error(res.msg)
            delToken()
            router.replace({path:'/login'})
        }
        return res
    },
    error => {
        MessagePlugin.error({content:JSON.stringify(error)})
        return error
    }
)

export default service
